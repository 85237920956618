import React, { useState } from 'react';

import {
    Spacing,
    SliderStage,
    SharedStyles,
    Container,
} from '../kenra-storybook/index';

const { StSectionInfo, StTitle, StImgDesc, StInnerText } = SharedStyles;

function findNavId(yearAsString, markers) {
    const year = parseInt(yearAsString);
    let navId = markers.length - 1;
    while (navId !== 0 && year <= markers[navId - 1].cutoff) {
        navId--;
    }
    return navId;
}

function formatTimelineText(year, text) {
    return `<h3>${year}</h3><p>${text}</p>`;
}

export default function AboutUs({ content }) {
    const [articleToRender, setArticleToRender] = useState(0);

    const { title, subtitle, items } = content;

    const splitSubtitle = subtitle.split('\\n\\n');
    const navMarkers = [
        {
            id: 0,
            title: "30'S",
            cutoff: 1959,
        },
        {
            id: 1,
            title: "60'S",
            cutoff: 1979,
        },
        {
            id: 2,
            title: "80'S",
            cutoff: 1999,
        },
        {
            id: 3,
            title: "00'S",
            cutoff: 2009,
        },
        {
            id: 4,
            title: "10'S",
            cutoff: 3000,
        },
    ];
    const binnedItems = [];
    if (items) {
        items.forEach(item => {
            binnedItems.push({
                id: findNavId(item.year, navMarkers) || 0,
                img: item.timeLineImage,
                text: formatTimelineText(item.year, item.shortDescription),
            });
        });
    }

    // const articleToRender = 0
    let article = null;
    if (items && articleToRender < items.length && items[articleToRender]) {
        let item = items[articleToRender];
        article = {
            title: item.articleTitle,
            imageUrl: item.articleImage,
            caption: item.articleImageCaption,
            subtitle: item.articleSubtitle,
            text: item.articleText.split('\\n\\n'),
        };
    }

    return (
        <>
            <Spacing>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h1>{title}</h1>
                        </StTitle>
                        {splitSubtitle.map(item => {
                            return <p>{item}</p>;
                        })}
                    </Container>
                </StSectionInfo>
            </Spacing>

            <Spacing>
                <SliderStage
                    itemsNav={navMarkers}
                    itemsContent={binnedItems}
                    onClick={index => {
                        setArticleToRender(index);
                    }}
                />
            </Spacing>

            {article && (
                <Spacing>
                    <Container>
                        <StImgDesc>
                            <div>
                                <StTitle alignLeft mobileSizeSmall>
                                    <h2>{article.title} </h2>
                                </StTitle>
                                <img
                                    src={article.imageUrl}
                                    alt=""
                                    style={{ maxHeight: 490 }}
                                />
                                <figcaption>{article.caption}</figcaption>
                            </div>
                        </StImgDesc>
                        <StInnerText>
                            <StTitle alignLeft mobileSizeSmall>
                                <h2>{article.subtitle}</h2>
                            </StTitle>
                            {article.text.map(item => {
                                return <p>{item}</p>;
                            })}
                        </StInnerText>
                    </Container>
                </Spacing>
            )}
        </>
    );
}
