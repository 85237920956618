import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import AboutUs from 'components/AboutUs';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function CarryUsPage(props) {
    let {
        data: { contentfulUtilityJsonModel, contentfulPage },
    } = props;

    if (!contentfulUtilityJsonModel.content) {
        return null;
    }

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'about-us'}
            />
            <AboutUs content={contentfulUtilityJsonModel.content} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulUtilityJsonModel(
            type: { eq: "AboutPage" }
            node_locale: { eq: $locale }
        ) {
            title
            type
            content {
                title
                subtitle
                items {
                    timeLineImage
                    year
                    shortDescription
                    articleTitle
                    articleImage
                    articleImageCaption
                    articleSubtitle
                    articleText
                }
            }
            node_locale
        }
        contentfulPage(
            title: { eq: "About Us" }
            node_locale: { eq: $locale }
        ) {
            ...ContentfulPageFields
        }
    }
`;
