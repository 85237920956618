/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
// import { MetaPixel } from 'helpers/metaPixel';
import TikTokPixel from 'helpers/tiktokPixel';

function SEO({ description, lang, title, titleTemplate, slug, isPro }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const url = 'https://www.kenraprofessional.com/' + slug;

    const seoTitle = title ? title : '';
    const seoTitleTemplate = titleTemplate
        ? titleTemplate
        : `%s | ${site.siteMetadata.title}`;
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={seoTitle}
            titleTemplate={seoTitleTemplate}
        >
            <meta name="description" content={metaDescription} />
            <meta name="og:url" content={url} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:title" content={title} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            {/* {MetaPixel(isPro)} */}
            {TikTokPixel()}
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
};

export default SEO;
